import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 720.000000 400.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">

<path d="M3769 2596 c-59 -18 -75 -36 -82 -91 -4 -27 -9 -60 -13 -72 l-6 -22
38 20 c21 10 44 19 51 19 14 0 18 25 6 33 -5 2 -2 2 4 1 7 -2 20 10 28 26 14
26 21 30 58 30 35 0 47 -6 74 -35 18 -19 39 -35 48 -35 13 0 12 7 -6 43 -12
23 -30 50 -41 60 -25 24 -114 37 -159 23z"/>
<path d="M3826 2477 c7 -20 35 -30 47 -18 6 6 16 5 28 -2 10 -6 21 -8 25 -4
10 10 1 17 -27 22 -13 3 -36 7 -51 11 -22 4 -27 2 -22 -9z"/>
<path d="M3701 2394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3665 2360 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4043 2273 c-28 -5 -30 -33 -3 -33 15 0 20 -7 20 -25 0 -14 -3 -24
-7 -23 -13 4 -73 -24 -73 -33 0 -5 -5 -9 -11 -9 -19 0 -48 -41 -42 -58 12 -33
25 -44 35 -30 6 9 7 5 3 -9 -5 -18 0 -27 27 -43 31 -18 33 -19 40 -2 5 12 7 9
7 -12 1 -21 5 -27 16 -22 8 3 15 0 15 -7 0 -9 3 -8 9 1 7 11 9 11 14 -1 8 -21
52 -16 51 6 -1 9 2 14 7 11 5 -3 9 -14 9 -25 0 -11 6 -21 13 -23 46 -14 229
-82 261 -97 34 -16 97 -17 81 -1 -3 3 3 14 12 24 14 16 15 21 4 27 -11 7 -9
14 7 37 11 16 23 49 26 74 6 45 6 45 -9 16 -8 -15 -15 -35 -15 -42 0 -8 -8
-14 -17 -14 -9 0 -13 -3 -10 -7 4 -3 2 -12 -4 -19 -9 -11 -14 -11 -26 2 -16
15 -9 32 27 62 13 10 13 11 -2 6 -10 -3 -18 -2 -18 4 0 5 9 12 21 15 29 8 15
25 -16 19 -14 -2 -25 0 -25 6 0 16 28 34 42 26 7 -4 0 7 -16 24 -35 38 -39 38
-76 12 l-28 -20 13 -60 c11 -50 10 -60 -1 -60 -8 0 -14 7 -14 16 0 11 -6 14
-17 10 -17 -6 -17 -5 -1 7 22 17 7 42 -21 34 -12 -3 -21 -1 -21 4 0 5 6 9 14
9 15 0 28 24 19 34 -10 10 -23 7 -20 -6 1 -6 -9 -14 -23 -18 -18 -4 -21 -8
-11 -14 10 -7 10 -10 1 -16 -9 -6 -6 -10 9 -15 13 -4 29 -1 39 7 15 12 16 11
3 -4 -7 -10 -23 -18 -36 -18 -25 0 -86 38 -93 58 -3 10 3 9 22 -3 15 -10 29
-15 32 -12 3 3 -8 13 -25 23 -17 10 -31 23 -31 28 0 5 -6 1 -14 -9 -9 -12 -11
-26 -6 -35 14 -25 158 -103 180 -97 11 3 20 2 20 -2 0 -5 18 -15 40 -24 40
-15 54 -37 25 -37 -8 0 -38 11 -67 25 -58 28 -62 29 -53 15 10 -17 -29 2 -50
24 -11 12 -36 32 -55 45 -19 13 -42 33 -51 44 -16 19 -16 19 -22 -6 -8 -28
-37 -45 -37 -22 0 8 7 15 15 15 18 0 20 36 3 46 -7 5 -9 1 -6 -13 4 -16 1 -20
-18 -20 -15 0 -24 5 -24 16 -1 13 -3 13 -16 -4 -16 -19 -16 -19 -9 5 4 14 12
30 17 37 5 7 5 14 -2 18 -5 3 -10 -1 -10 -9 0 -9 -5 -16 -11 -16 -5 0 -7 -5
-3 -12 4 -7 3 -8 -4 -4 -8 5 -11 -2 -8 -23 2 -17 0 -28 -4 -26 -13 8 -18 53
-7 64 18 18 -1 24 -25 7 -12 -9 -31 -16 -41 -16 -15 0 -12 8 20 49 41 53 82
78 110 66 10 -4 28 -9 41 -10 17 -3 22 2 22 21 0 13 4 24 8 24 4 0 8 9 9 20 2
25 -6 26 -22 3 -12 -17 -16 -16 -53 5 -22 12 -44 21 -48 21 -5 -1 -19 -4 -31
-6z m57 -53 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17
-5 25 -10z m-120 -90 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2
0 4 -4 4 -10z m129 -109 c18 -11 5 -31 -20 -31 -21 0 -22 1 -13 24 7 18 13 19
33 7z m381 -25 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z"/>
<path d="M3579 2254 c-14 -7 -38 -32 -53 -55 -15 -23 -23 -38 -18 -35 6 3 13
2 16 -3 3 -5 -10 -16 -29 -25 -30 -15 -49 -32 -50 -46 0 -3 -9 -17 -19 -33
-15 -21 -16 -30 -6 -42 13 -16 5 -75 -11 -75 -5 0 -9 5 -9 11 0 16 -26 -14
-34 -39 -11 -34 -62 -86 -76 -77 -9 5 -10 1 -6 -13 6 -18 2 -23 -26 -30 -31
-8 -33 -11 -34 -53 -2 -41 7 -58 30 -59 5 0 2 8 -7 18 -11 13 -12 20 -3 28 6
6 17 9 24 6 6 -2 12 1 12 7 0 6 4 11 10 11 17 0 70 58 57 64 -7 2 -2 5 9 5 18
1 21 -5 21 -39 0 -22 6 -46 13 -55 10 -12 10 -15 -1 -15 -10 0 -14 -13 -14
-42 1 -52 -16 -74 -44 -58 -27 14 -34 13 -28 -4 4 -11 1 -13 -10 -9 -12 5 -14
3 -8 -7 5 -9 4 -11 -4 -6 -6 4 -9 11 -6 16 5 8 -36 22 -44 15 -2 -3 2 -5 10
-5 11 0 11 -2 -1 -10 -12 -8 -12 -10 5 -10 17 0 17 -2 5 -10 -14 -9 -13 -11
19 -14 8 0 16 -8 18 -16 3 -9 1 -11 -4 -5 -4 6 -25 11 -46 12 -21 1 -45 7 -53
14 -11 9 -14 8 -14 -4 0 -8 -15 -20 -32 -26 -31 -10 -32 -10 -13 5 19 16 19
16 -7 10 -16 -3 -28 -1 -30 5 -2 7 -10 4 -18 -7 -13 -16 -13 -17 2 -12 9 4 19
1 22 -7 3 -9 0 -12 -8 -9 -7 3 -20 -2 -29 -10 -8 -8 -17 -12 -21 -9 -3 4 -6
-3 -6 -15 0 -11 -4 -23 -8 -26 -5 -3 -6 8 -4 25 4 29 -10 40 -24 17 -4 -7 -3
-8 4 -4 17 10 15 -1 -5 -24 -21 -24 -35 -26 -26 -3 12 31 -15 4 -36 -37 -26
-51 -26 -55 4 -118 14 -29 25 -56 25 -61 0 -8 48 -143 70 -196 4 -11 18 -49
31 -85 12 -36 28 -76 36 -90 8 -14 22 -47 31 -74 10 -27 22 -51 28 -53 6 -2
20 24 31 59 16 48 18 66 9 81 -9 16 -7 21 12 34 12 9 22 22 22 29 0 14 27 73
71 156 10 20 19 43 19 51 0 8 15 48 33 88 19 41 39 92 46 114 14 44 20 62 45
118 l16 38 -27 -16 c-48 -29 -144 -191 -158 -268 -4 -19 -13 -38 -21 -41 -16
-6 -19 19 -4 29 6 3 10 13 10 22 0 8 16 49 35 90 40 87 42 94 15 62 -17 -21
-19 -21 -13 -4 3 11 12 29 19 39 8 11 11 30 8 43 -8 28 41 78 86 88 17 4 30
13 30 21 0 8 14 23 31 33 22 13 39 37 55 77 27 66 23 81 -11 42 -13 -16 -26
-24 -29 -20 -6 10 30 83 49 98 7 5 17 26 22 45 6 20 19 39 34 46 20 11 21 13
5 13 -16 0 -18 6 -13 45 3 31 12 52 28 67 l24 21 -22 -6 c-16 -3 -23 -1 -23 9
0 23 4 29 18 27 8 0 11 5 8 14 -3 8 -6 17 -6 19 0 2 -10 4 -22 4 l-23 1 24 13
c14 8 30 33 42 67 10 29 19 53 19 54 0 7 -53 -1 -71 -11z m51 -21 c0 -9 -3
-14 -6 -10 -3 3 -22 -4 -42 -15 l-37 -20 20 22 c16 18 42 34 63 39 1 1 2 -6 2
-16z m-50 -193 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-93 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m73
7 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-80
-55 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m-19 -43 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-196 -384
c16 3 24 0 22 -7 -2 -6 -9 -11 -15 -10 -7 1 -23 2 -37 3 -16 0 -25 7 -25 17 0
13 3 14 15 4 8 -7 26 -10 40 -7z m-65 -3 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15
10 0 2 7 4 15 4 8 0 15 -4 15 -10z m115 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11
10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-123 -178 c-6 -2 -10 -8 -6 -13 3 -5 -1
-9 -9 -9 -8 0 -17 8 -19 18 -4 16 -1 18 21 14 14 -3 20 -7 13 -10z m-19 -79
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M4228 2253 c-4 -15 1 -19 24 -21 24 -2 29 1 26 15 -5 25 -44 29 -50
6z"/>
<path d="M3985 2250 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M4200 2160 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4015 2120 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4318 2103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4260 2090 c0 -5 12 -10 28 -10 21 0 24 2 12 10 -20 13 -40 13 -40 0z"/>
<path d="M3648 2033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3676 1965 c-9 -14 -21 -23 -26 -20 -6 4 -10 3 -11 -2 0 -4 -2 -21
-3 -36 -1 -16 -8 -32 -16 -37 -11 -7 -1 -10 33 -11 26 0 63 -1 82 -1 39 -1 50
5 40 22 -6 9 -10 9 -17 -1 -6 -10 -7 -7 -3 9 4 13 2 22 -4 22 -6 0 -20 18 -31
40 -11 22 -22 40 -24 40 -2 0 -11 -11 -20 -25z"/>
<path d="M3316 1923 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3270 1890 c0 -11 4 -20 9 -20 11 0 22 26 14 34 -12 12 -23 5 -23
-14z"/>
<path d="M3315 1889 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M3803 1828 c-12 -19 -33 -60 -33 -64 0 -12 33 -1 52 18 13 13 29 22
35 20 16 -4 17 10 1 26 -16 16 -45 15 -55 0z"/>
<path d="M3903 1793 c-7 -2 -13 -14 -13 -25 0 -16 -5 -19 -22 -14 -13 3 -40 0
-62 -6 -29 -9 -42 -20 -52 -45 -18 -41 -18 -40 10 -47 13 -3 31 -9 40 -12 10
-4 16 -1 16 9 0 13 5 13 33 4 17 -7 34 -15 37 -18 10 -12 51 -30 63 -27 7 2
14 -1 15 -7 1 -5 -14 -11 -34 -13 -26 -2 -34 -7 -30 -18 4 -9 -1 -14 -14 -14
-11 0 -20 -6 -20 -13 0 -8 -17 -19 -39 -25 -24 -8 -41 -20 -44 -32 -6 -22 2
-30 61 -58 34 -17 42 -18 42 -6 0 9 5 12 10 9 6 -4 8 -10 5 -15 -13 -21 98
-66 131 -53 18 7 18 57 -1 92 -8 16 -13 37 -11 47 8 40 11 49 18 54 4 3 9 15
11 25 1 11 6 25 10 32 4 7 7 38 6 71 l-2 58 -61 21 c-34 11 -68 23 -76 26 -8
3 -21 3 -27 0z m107 -305 c0 -12 7 -29 16 -39 17 -19 12 -39 -10 -39 -7 0 -35
11 -62 25 -27 14 -57 22 -66 19 -13 -4 -18 0 -18 16 l0 20 40 -20 c21 -11 50
-20 64 -20 13 0 26 -6 29 -12 2 -7 8 -10 12 -6 12 11 -24 48 -40 41 -27 -10
-38 9 -13 22 34 19 48 17 48 -7z"/>
<path d="M3315 1720 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M3255 1710 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3212 1670 c15 -23 28 -26 28 -6 0 8 -7 17 -16 20 -24 9 -25 8 -12
-14z"/>
<path d="M3325 1670 c-3 -5 -1 -20 6 -32 12 -22 12 -22 19 -3 12 33 -9 62 -25
35z"/>
<path d="M3280 1650 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3720 1631 c0 -5 7 -16 15 -25 8 -8 15 -24 15 -37 0 -18 -2 -19 -15
-9 -8 7 -15 8 -15 2 0 -5 6 -15 14 -21 8 -7 17 -23 21 -38 10 -40 16 -21 18
55 2 64 1 71 -18 76 -28 8 -35 7 -35 -3z"/>
<path d="M3170 1604 c-33 -13 -33 -14 -5 -8 17 3 34 10 40 15 11 11 8 11 -35
-7z"/>
<path d="M3830 1570 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M3347 1508 c-9 -31 -2 -33 11 -3 6 14 8 25 3 25 -5 0 -11 -10 -14
-22z"/>
<path d="M3600 1328 c0 -7 -7 -25 -15 -41 -25 -48 -145 -354 -151 -386 -8 -39
-2 -39 34 0 26 28 48 35 103 30 9 0 45 -28 80 -61 35 -33 70 -60 78 -60 17 0
71 49 71 64 0 6 9 22 20 36 11 14 20 33 20 43 0 9 4 17 8 17 11 0 52 74 52 94
0 9 5 16 10 16 6 0 10 9 10 19 0 11 4 21 9 23 4 2 11 12 15 24 5 18 0 23 -36
33 -64 19 -202 88 -252 127 -49 37 -56 40 -56 22z"/>
<path d="M4111 1164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2791 1084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4093 935 c0 -33 2 -45 4 -27 2 18 2 45 0 60 -2 15 -4 0 -4 -33z"/>
<path d="M4071 944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4451 884 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4072 845 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4092 850 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2810 836 c0 -8 -3 -16 -7 -19 -5 -2 -3 -2 4 -1 7 2 15 -1 18 -6 10
-17 25 -11 25 9 0 10 -7 22 -16 25 -22 8 -24 8 -24 -8z"/>
<path d="M2900 801 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5 3
-10 2 -10 -4z"/>
<path d="M2885 760 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6 0
-7 -4 -4 -10z"/>
<path d="M3878 763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>

		</Box >
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
